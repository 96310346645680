import React from 'react';
import {Header} from "../common";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 88px;
  margin-bottom: 140px;
`;
const ContentBox = styled.div`
  width: 1080px;
`;
const ImgBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const TitleBox = styled.div`
  margin-top: 50px;
  font-size: 46px;
  font-weight: 700;
  color: ${props => props.theme.color.dark_100};
`;

const ItemBox = styled.div`
  padding: 24px 30.5px;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
`;
const Box = styled.div`
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 8px;
`;


const GreenText = styled.span`
  color: ${props => props.theme.color.green_100};
`;

const GrayText = styled.span`
  color: ${props => props.theme.color.dark_200};
`;


const ViewBox = styled(Box)`
  padding: 32px 45px;
  margin-right: 16px;
`;

const BackgroundBox = styled.div`
  background-color: ${props => props.theme.color.white_201};
  border-radius: 8px;
`;

const ViewBackground = styled(BackgroundBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 263px;
  height: 179px;
  border-radius: 8px;

  & + & {
    margin-top: 32px;
  }
`;
const ViewGreenText = styled(GreenText)`
  font-size: 54px;
  font-weight: 700;
`;
const ViewGrayText = styled(GrayText)`
  font-size: 24px;
  font-weight: 500;
  margin-top: 5px;
`;
const NationBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25.5px 31px;
  width: 648px;
  height: 296px;
`;
const NationRowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const GenderBox = styled(BackgroundBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 48px;
  flex: 1;

  & + & {
    margin-left: 16px;
  }
`;

const GenderGreenText = styled(GreenText)`
  font-size: 32px;
  font-weight: 700;
  line-height: 43px;
`;

const GenderGrayText = styled(GrayText)`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 5px;
`;
const GenderColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const GenderPercent = styled(GreenText)`
  font-size: 24px;
  font-weight: 800;
`;
const GenderTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NationBackground = styled(BackgroundBox)`
  height: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;
const NationGrayText = styled(GrayText)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 16px;
`;
const ContactBox = styled(Box)`
  width: 329px;
  height: 144px;
  padding: 32px 16px;
`;
const PDFBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 303px;
  height: 144px;
  padding: 24px 16px;
`;
const ContactRowBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;
const ContactText = styled.span`
  font-size: 24px;
  color: ${props => props.theme.color.dark_100};
  line-height: 29px;
  font-weight: 700;
  margin-left: 8px;
`;
const ContactGreenText = styled(GreenText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
`;
const ContactRowItem = styled(NationRowBox)`
  align-items: center;

  & + & {
    margin-top: 16px;
  }
`;
const PDFRowItem = styled(NationRowBox)`
  align-items: center;
`;
const PDFDownloadButton = styled.button`
  border: 1px solid ${props => props.theme.color.dark_300};
  background-color: ${props => props.theme.color.white_201};
  width: 100%;
  border-radius: 99px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  font-weight: 600;
  color: ${props => props.theme.color.dark_100_green_100};
  font-size: 24px;
`;
const Advertise = () => {
    const theme = useTheme()
    const currentLang = useSelector((state) => state?.translation.lang);
    const {t} = useTranslation()
    return (
        <div>
            <Header/>
            <ContentWrapper>
                <ImgBox>
                    <img src={`${theme.icon.path}/logo_deeplol.svg`} width={620} height={109} alt={''}/>
                </ImgBox>
                <TitleBox>
                    <span>{t('advertise.title')}</span>
                </TitleBox>
                <ItemBox>
                    <ViewBox>
                        <ViewBackground>
                            <ViewGreenText>21.3M</ViewGreenText>
                            <ViewGrayText>{t('advertise.views')}</ViewGrayText>
                        </ViewBackground>
                        <ViewBackground>
                            <ViewGreenText>850K</ViewGreenText>
                            <ViewGrayText>{t('advertise.visit')}</ViewGrayText>
                        </ViewBackground>
                    </ViewBox>
                    <div>
                        <NationBox>
                            <NationRowBox>
                                <GenderBox>
                                    <GenderColumn>
                                        <GenderTextWrapper>
                                            <GenderGreenText>87</GenderGreenText>
                                            <GenderPercent>%</GenderPercent>
                                        </GenderTextWrapper>
                                        <GenderGrayText>{t('advertise.male')}</GenderGrayText>
                                    </GenderColumn>
                                    <GenderColumn>
                                        <GenderTextWrapper>
                                            <GenderGreenText>13</GenderGreenText>
                                            <GenderPercent>%</GenderPercent>
                                        </GenderTextWrapper>
                                        <GenderGrayText>{t('advertise.female')}</GenderGrayText>
                                    </GenderColumn>
                                </GenderBox>
                                <GenderBox>
                                    <GenderColumn>
                                        <GenderTextWrapper>
                                            <GenderGreenText>76</GenderGreenText>
                                            <GenderPercent>%</GenderPercent>
                                        </GenderTextWrapper>
                                        <GenderGrayText>PC</GenderGrayText>
                                    </GenderColumn>
                                    <GenderColumn>
                                        <GenderTextWrapper>
                                            <GenderGreenText>24</GenderGreenText>
                                            <GenderPercent>%</GenderPercent>
                                        </GenderTextWrapper>
                                        <GenderGrayText>Mobile</GenderGrayText>
                                    </GenderColumn>
                                </GenderBox>
                            </NationRowBox>
                            <NationBackground>
                                <GenderColumn>
                                    <img src={'/images/flag_kor.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        58%
                                    </NationGrayText>
                                </GenderColumn>
                                <GenderColumn>
                                    <img src={'/images/flag_jpn.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        9%
                                    </NationGrayText>
                                </GenderColumn>
                                <GenderColumn>
                                    <img src={'/images/flag_fr.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        6%
                                    </NationGrayText>
                                </GenderColumn>
                                <GenderColumn>
                                    <img src={'/images/flag_vietnam.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        6%
                                    </NationGrayText>
                                </GenderColumn>
                                <GenderColumn>
                                    <img src={'/images/flag_pol.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        5%
                                    </NationGrayText>
                                </GenderColumn>
                                <GenderColumn>
                                    <img src={'/images/flag_bra.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        5%
                                    </NationGrayText>
                                </GenderColumn>
                                <GenderColumn>
                                    <img src={'/images/flag_us.svg'} width={50} height={33} alt={''}/>
                                    <NationGrayText>
                                        4%
                                    </NationGrayText>
                                </GenderColumn>
                            </NationBackground>
                        </NationBox>
                        <ContactRowBox>
                            <ContactBox>
                                <ContactRowItem>
                                    <img src={`${theme.icon.path}/icon_mail.svg`} width={37} height={37} alt={''}/>
                                    <ContactText>{t('advertise.contact')}</ContactText>
                                </ContactRowItem>
                                <ContactRowItem>
                                    <img src={`${theme.icon.path}/icon_green_arrow.svg`} width={23} height={18}
                                         alt={''}/>
                                    <ContactGreenText>contact@gameeye.co.kr</ContactGreenText>
                                </ContactRowItem>
                            </ContactBox>
                            <PDFBox>
                                <PDFRowItem>
                                    <img src={`${theme.icon.path}/icon_pdf.svg`} width={28} alt={''}/>
                                    <ContactText>{t('advertise.guide')}</ContactText>
                                </PDFRowItem>
                                <a href={currentLang === 'KOR' ? 'https://drive.google.com/file/d/1Au_wTzKQ2tqeShkFtCCX1lJFph3aoDPh/view' : 'https://drive.google.com/file/d/1urkmOALcUllF4Z3dmaN_mSlvYWezeurw/view'}
                                   target={'_blank'}>
                                    <PDFDownloadButton>
                                        {t('advertise.pdfDownload')}
                                        <img src={`${theme.icon.path}/icon_pdf_download.svg`} width={40} alt={''}/>
                                    </PDFDownloadButton>
                                </a>
                            </PDFBox>
                        </ContactRowBox>
                    </div>
                </ItemBox>
            </ContentWrapper>
        </div>
    );
};

export default Advertise;