import {useQueries} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {getDataDragonRegion} from "../../i18n/languages";
import {useDispatch, useSelector} from "react-redux";
import {
    SET_ARGUMENT_INFO,
    SET_ITEM,
    SET_ITEM_INFO,
    SET_NOTICE,
    SET_OLD_SEASON,
    SET_RUNE,
    SET_SEASON,
    SET_SPELL
} from "../../store/reducer/imageStore";
import {GET_CHAMPIONS_VERSIONS_SUCCESS} from "../../store/reducer/champions";
import {secretApi} from "../../api/api";
import axios from "axios";
// secretApi.get(`${process.env.REACT_APP_CACHE_API}common/season-list`),
//     secretApi.get(`${process.env.REACT_APP_CACHE_API}common/tier-boundary`),
//     secretApi.get(`${process.env.REACT_APP_CACHE_API}common/old-season`),
//     secretApi.get(`${process.env.REACT_APP_CACHE_API}common/item-info`),
// secretApi.get(`${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
//     secretApi.get(`${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
//     secretApi.get(`${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
const DataLayer = ({children}) => {
    const version = useSelector((state) => state.gameVersion.cloudflareVersion);
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    const currentLang = useSelector((state) => state.translation.lang);
    const query = useQueries({
        queries: [
            {
                queryKey: ['seasonList'],
                queryFn: async () => {
                    const result = await secretApi.get(`${process.env.REACT_APP_CACHE_API}common/season-list`);
                    const data = result.data
                    dispatch({
                        type: SET_SEASON,
                        data: data,
                    })
                    return result
                },
                staleTime: Infinity
            },
            {
                queryKey: ['oldSeason'],
                queryFn: async () => {
                    const result = await secretApi.get(`${process.env.REACT_APP_CACHE_API}common/old-season`);
                    const data = result.data.season_list
                    dispatch({
                        type: SET_OLD_SEASON,
                        data: data,
                    })
                    return result
                },
                staleTime: Infinity

            },
            {
                queryKey: ['itemInfo'],
                queryFn: async () => {
                    const result = await secretApi.get(`${process.env.REACT_APP_CACHE_API}common/item-info?version=${version}.1`)
                    const data = result.data
                    dispatch({
                        type: SET_ITEM_INFO,
                        data: data,
                    })
                    return result
                },
                staleTime: Infinity
            },
            {
                queryKey: ['augments', i18n.language],
                queryFn: async () => {
                    const result = await axios.get(`https://deeplol-ddragon-cdn.deeplol.gg/latest/cdragon/arena/${getDataDragonRegion(i18n.language).toLowerCase()}.json`)
                    const data = result.data.augments
                    dispatch({
                        type: SET_ARGUMENT_INFO,
                        data: data,
                    })
                    return data
                },
                staleTime: Infinity
            },
            {
                queryKey: ['appRune', i18n.language],
                queryFn: async () => {
                    const result = await axios.get(`${process.env.REACT_APP_RUNE}?version=${version}&lang=${getDataDragonRegion(i18n.language)}`)
                    const data = result.data
                    dispatch({
                        type: SET_RUNE,
                        data: data,
                    })
                    return data
                },
                staleTime: Infinity
            },
            {
                queryKey: ['appSpell', i18n.language],
                queryFn: async () => {
                    const result = await axios.get(`${process.env.REACT_APP_SPELL}?version=${version}&lang=${getDataDragonRegion(i18n.language)}`)
                    const data = result.data.data
                    dispatch({
                        type: SET_SPELL,
                        data: data,
                    })
                    return data
                },
                staleTime: Infinity,
            },
            {
                queryKey: ['appItem', i18n.language],
                queryFn: async () => {
                    const result = await axios.get(`${process.env.REACT_APP_ITEM}?version=${version}&lang=${getDataDragonRegion(i18n.language)}`)
                    const data = result.data.data
                    if (data?.[6701]) {
                        if (getDataDragonRegion(i18n.language) === 'ko_KR') {
                            data[6701].description = "<mainText><stats>공격력 <attention>55</attention><br>물리 관통력 <attention>18</attention><br>이동 속도 <attention>5%</attention></stats><br><br><passive>준비</passive><br>8초 동안 챔피언과 전투를 벌이지 않으면 물리 관통력이 5 ~ 10레벨 증가합니다. 이 물리 관통력 증가 효과는 챔피언에게 피해를 입힐 경우 3초 후에 사라집니다.<br><br><passive>탈출</passive><br>피해를 입힌 챔피언이 3초 안에 죽으면 1.5초 동안 이동 속도가 150 증가했다가 점차 감소합니다.</mainText>"

                        } else if (getDataDragonRegion(i18n.language) === 'en_US') {
                            data[6701].description = "<mainText><stats><attention>55</attention> Attack Damage<br><attention>18</attention> Lethality<br><attention>5%</attention> Move Speed</stats><br><br><passive>Preparation</passive><br>After being out-of-combat with enemy champions for 8 seconds, gain 5 − 10 (based on level) lethality. This bonus remains for 3 seconds after dealing damage to an enemy champion.<br><br><passive>Extraction</passive><br>Scoring a Damage rating takedown against an enemy champion within 3 seconds of damaging them grants you 150 bonus movement speed that decays over 1.5 seconds.</mainText>"
                        }
                    }
                    dispatch({
                        type: SET_ITEM,
                        data: data,
                    })
                    return data
                },
                staleTime: Infinity
            },
            {
                queryKey: ['appNotice', i18n.language],
                queryFn: async () => {
                    const langTrans = () => {
                        if (currentLang === 'KOR') {
                            return 'kr'
                        } else if (currentLang === 'ja') {
                            return 'jp'
                        }
                        return 'en'
                    }
                    const result = await secretApi.get(`${process.env.REACT_APP_NEW_API}common/notice?language=${langTrans()}`);
                    const data = result.data
                    dispatch({
                        type: SET_NOTICE,
                        data: data,
                    })
                    return data
                },
                staleTime: Infinity
            },
            {
                queryKey: ['championVersion'],
                queryFn: async () => {
                    const url = `${process.env.REACT_APP_CACHE_API}champion/version?cnt=3`;
                    const result = await secretApi.get(url).then((result) => result.data.game_version_list);
                    dispatch({
                        type: GET_CHAMPIONS_VERSIONS_SUCCESS,
                        data: {
                            versions: result,
                        }
                    })
                    return result
                },
                staleTime: Infinity
            }
        ]
    });

    // useMemo(() => {
    //     if(query[8].data) {
    //         dispatch({
    //             type :GET_CHAMPIONS_VERSIONS_SUCCESS,
    //             data: {
    //                 versions: query[8].data,
    //             }
    //         })
    //     }
    //     if (query[7].data) {
    //         dispatch({
    //             type: SET_NOTICE,
    //             data: query[7].data,
    //         })
    //     }
    //     if (query[6].data) {
    //         dispatch({
    //             type: SET_ITEM,
    //             data: query[6].data,
    //         })
    //     }
    //     if (query[5].data) {
    //         dispatch({
    //             type: SET_SPELL,
    //             data: query[5].data,
    //         })
    //     }
    //     if (query[4].data) {
    //         dispatch({
    //             type: SET_RUNE,
    //             data: query[4].data,
    //         })
    //     }
    //     if (query[3].data) {
    //         dispatch({
    //             type: SET_ARGUMENT_INFO,
    //             data: query[3].data,
    //         })
    //     }
    // }, [query]);

    return children
};

export default DataLayer;