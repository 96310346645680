import React, {useCallback, useMemo, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import {getIngamePositionObj, sortChampData} from "../../function";
import {secretApi} from "../../api/api";

const getSwapLaneValue = ({top, mid, isSwap, isSwapTeam}) => {
    if (isSwap && isSwapTeam) {
        return {
            top: mid,
            mid: top,
        }
    }
    return {
        top, mid
    }
}
const UseAiItemApi = () => {
    const summoner = useSelector((state) => state.summoner.summoner);
    const ingame = useSelector((state) => state.summoner.ingame);
    const [initialLoading, setInitialLoading] = useState(true);

    const [selectedChamp, setSelectedChamp] = useState(0);

    const onChangeSelectedChamp = useCallback((id) => {
        setSelectedChamp(id)
    }, []);

    const currentPlayer = useMemo(() => {
        try {
            const summoner_id = summoner.summoner_basic_info_dict.summoner_id
            const player = ingame.basic.participants_list.find((data) => data.summoner_id === summoner_id);
            return player
        } catch (e) {
            return {}
        }
    }, [ingame, summoner]);

    const map = ingame.basic.map
    const targetChampion = currentPlayer.champion_id
    const targetTeam = currentPlayer.side
    const positionObj = useMemo(() => {
        return getIngamePositionObj(sortChampData(ingame.basic.participants_list, [{
            value: 'teamId',
            sortType: false
        }]));
    }, [ingame.basic.participants_list]);

    const swapChampArr = useMemo(() => {
        try {
            if (currentPlayer.position !== 'Top' && currentPlayer.position !== 'Middle') {
                setSelectedChamp(0)
                return []
            }
            const enemyTeam = ingame.basic.participants_list.filter((data) => data.side !== targetTeam);
            const enemyTop = enemyTeam.find((data) => data.position === 'Top');
            const enemyMid = enemyTeam.find((data) => data.position === 'Middle')


            if (currentPlayer.position === 'Middle') {
                setSelectedChamp(enemyMid.champion_id)
                return [enemyMid, enemyTop]
            }


            setSelectedChamp(enemyTop.champion_id)
            return [enemyTop, enemyMid]
        } catch (e) {
            return []
        }

    }, [currentPlayer, ingame]);

    const swapLane = useMemo(() => {
        if (currentPlayer.position !== 'Top' && currentPlayer.position !== 'Middle') {
            return false
        }

        if (selectedChamp === 0) {
            return false
        }


        return swapChampArr[0].champion_id !== selectedChamp

    }, [swapChampArr, selectedChamp]);


    const getIngameAIItem = () => {
        if (map !== 11) {
            return {state: false}
        }
        const url = new URL(`${process.env.REACT_APP_CACHE_API}ingame/ai-item`);
        const blueTop = positionObj.Top.find((data) => data.side === 'BLUE');
        const blueMiddle = positionObj.Middle.find((data) => data.side === 'BLUE');
        const blueJungle = positionObj.Jungle.find((data) => data.side === 'BLUE');
        const blueBot = positionObj.Bot.find((data) => data.side === 'BLUE');
        const blueSupport = positionObj.Supporter.find((data) => data.side === 'BLUE');


        const redTop = positionObj.Top.find((data) => data.side === 'RED');
        const redMiddle = positionObj.Middle.find((data) => data.side === 'RED');
        const redJungle = positionObj.Jungle.find((data) => data.side === 'RED');
        const redBot = positionObj.Bot.find((data) => data.side === 'RED');
        const redSupport = positionObj.Supporter.find((data) => data.side === 'RED');

        const obj = {
            blue: {
                Top: {champion_id: blueTop.champion_id, rune_id: blueTop.rune_detail_dict.perk_0},
                Jungle: {champion_id: blueJungle.champion_id, rune_id: blueJungle.rune_detail_dict.perk_0},
                Middle: {champion_id: blueMiddle.champion_id, rune_id: blueMiddle.rune_detail_dict.perk_0},
                Bot: {champion_id: blueBot.champion_id, rune_id: blueBot.rune_detail_dict.perk_0},
                Supporter: {champion_id: blueSupport.champion_id, rune_id: blueSupport.rune_detail_dict.perk_0},
            },

            red: {
                Top: {champion_id: redTop.champion_id, rune_id: redTop.rune_detail_dict.perk_0},
                Jungle: {champion_id: redJungle.champion_id, rune_id: redJungle.rune_detail_dict.perk_0},
                Middle: {champion_id: redMiddle.champion_id, rune_id: redMiddle.rune_detail_dict.perk_0},
                Bot: {champion_id: redBot.champion_id, rune_id: redBot.rune_detail_dict.perk_0},
                Supporter: {champion_id: redSupport.champion_id, rune_id: redSupport.rune_detail_dict.perk_0},
            },

            target_champion_id: targetChampion,

        }


        const is_target_blue = targetTeam === 'BLUE';

        const blueSwapValue = getSwapLaneValue({
            top: obj.blue.Top,
            mid: obj.blue.Middle,
            isSwap: swapLane,
            isSwapTeam: !is_target_blue
        });
        const redSwapValue = getSwapLaneValue({
            top: obj.red.Top,
            mid: obj.red.Middle,
            isSwap: swapLane,
            isSwapTeam: is_target_blue
        });

        url.searchParams.set('blue_top_champion_id', blueSwapValue.top.champion_id)
        url.searchParams.set('blue_top_rune_id', blueSwapValue.mid.rune_id)

        url.searchParams.set('blue_jug_champion_id', obj.blue.Jungle.champion_id)
        url.searchParams.set('blue_jug_rune_id', obj.blue.Jungle.rune_id)

        url.searchParams.set('blue_mid_champion_id', blueSwapValue.mid.champion_id)
        url.searchParams.set('blue_mid_rune_id', blueSwapValue.mid.rune_id)

        url.searchParams.set('blue_bot_champion_id', obj.blue.Bot.champion_id)
        url.searchParams.set('blue_bot_rune_id', obj.blue.Bot.rune_id)

        url.searchParams.set('blue_sup_champion_id', obj.blue.Supporter.champion_id)
        url.searchParams.set('blue_sup_rune_id', obj.blue.Supporter.rune_id)

        //red team
        url.searchParams.set('red_top_champion_id', redSwapValue.top.champion_id)
        url.searchParams.set('red_top_rune_id', redSwapValue.top.rune_id)

        url.searchParams.set('red_jug_champion_id', obj.red.Jungle.champion_id)
        url.searchParams.set('red_jug_rune_id', obj.red.Jungle.rune_id)

        url.searchParams.set('red_mid_champion_id', redSwapValue.mid.champion_id)
        url.searchParams.set('red_mid_rune_id', redSwapValue.mid.rune_id)

        url.searchParams.set('red_bot_champion_id', obj.red.Bot.champion_id)
        url.searchParams.set('red_bot_rune_id', obj.red.Bot.rune_id)

        url.searchParams.set('red_sup_champion_id', obj.red.Supporter.champion_id)
        url.searchParams.set('red_sup_rune_id', obj.red.Supporter.rune_id)

        //target
        url.searchParams.set('target_champion_id', obj.target_champion_id)
        url.searchParams.set('is_target_blue', is_target_blue);

        // return {
        //     state: false
        // }
        return secretApi.get(url.toString(), {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
           return res.data
        }).catch(() => ({"state": false}));
    }

    const query = useQuery({
        queryKey: ['ingameAiItem', targetChampion, swapLane, currentPlayer.summoner_id],
        queryFn: async () => await getIngameAIItem(),
        staleTime: 36000
    })

    return {query, swapChampArr, onChangeSelectedChamp, selectedChamp, currentPlayer,initialLoading};
};

export default UseAiItemApi;