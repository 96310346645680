import {useEffect, useRef} from "react";

const Ad = ({placementName, alias}) => {
    const elRef = useRef(null);

    const isHSorVideoSlider = () => {
        const validPlacements = [
            "horizontal_sticky",
            "mobile_horizontal_sticky",
            "video_slider",
        ];
        return validPlacements.includes(placementName);
    };

    useEffect(() => {
        let placement;
        console.log("[PROSPER] add", placementName);

        const handleAdManagerPush = (admanager, scope) => {
            if (placementName.includes(",")) {
                const [desktopPlacement, mobilePlacement] = placementName.split(",");
                scope.Config.buildPlacement((configBuilder) => {
                    configBuilder.add(desktopPlacement);
                    configBuilder
                        .addDefaultOrUnique(mobilePlacement)
                        .setBreakPoint("mobile");
                }, alias).display(elRef.current);
            } else if (placementName === "vertical_sticky") {
                scope.Config.verticalSticky().display();
            }
            //Vertical sticky inline
            else if (placementName === "vertical_sticky-left") {
               placement =  scope.Config.get('vertical_sticky','vertical_sticky-left-1').display(elRef.current);
            } else if (placementName === "vertical_sticky-right") {
                placement = scope.Config.get('vertical_sticky','vertical_sticky-right-1').display(elRef.current);
            }
            //end Vertical sticky inline
            else {
                placement = scope.Config.get(placementName, alias).display(
                    isHSorVideoSlider() ? {body: true} : elRef.current
                );
            }
        };


        const handleUnmount = (admanager, scope) => {
            console.log("[PROSPER] removed", placementName);

            if (placementName === "vertical_sticky") {
                scope.Config.verticalSticky().destroy();
            } else {
                admanager.removePlacement(placement.instance());
            }
        };

        // eslint-disable-next-line no-restricted-globals
        self.__VM.push(handleAdManagerPush);

        return () => {
            // eslint-disable-next-line no-restricted-globals
            self.__VM.push(handleUnmount);
        };
    }, []);

    return <div ref={elRef}></div>;
};

export default Ad;