import React from 'react';
import {onStorePuuId, storePuuIdLocal} from "../../function";
import WheelLink from "./WheelLink";

const ToSummonerLink = ({name, region, puu_id,extra, children, ...others}) => {
    const url = onStorePuuId(name, region, puu_id)

    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        storePuuIdLocal(name, region, puu_id,extra)
    }
    return <WheelLink {...others} target={'_blank'} onClick={onClick} to={url}>{children}</WheelLink>
};

export default ToSummonerLink;