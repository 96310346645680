import React, {useMemo} from "react";
import styled from "styled-components";
import InternalServerError from "../../../common/InternalError";
import MasteryRankTableModel from "../../../../Model/MasteryRankTableModel";
import useSortArray from "../../../../common/hooks/useSortArray";
import DetailMasteryTableHeader from "./ChampionDetailMasteryTableHeader";
import ChampionDetailMasteryTableContent from "./ChampionDetailMasteryTableContent";
import {capitalizeLetter} from "../../../../function";


const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`
  tr:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;


const initSort = [{
    value: 'rank', sortType: false, reverseSort: true,
}];

const initSortList = {
    rank: {
        value: 'rank',sortType:false, reverseSort: true,
    },
    tier: {
        value: 'tier', sortType: true,reverseSort: true,
    }, win_rate: {
        value: 'win_rate', sortType: true,
    }, play_rate: {
        value: 'play_rate', sortType: true,
    }, games: {
        value: 'games', sortType: true,
    }, riot_id_name: {
        value: 'riot_id_name', sortType: true,
    }, kda: {
        value: 'kda', sortType: true,
    }, pick_rate: {
        value: 'pick_rate', sortType: true,
    }, gd: {
        value: 'gd', sortType: true,
    }, ds: {
        value: 'ds', sortType: true,
    }
}

const ChampionDetailMasteryTable = ({ranks = []}) => {
    const {sort, sortList, onClickSort} = useSortArray({
        initSort: initSort, initSortList: initSortList,
    });



    const model = useMemo(() => new MasteryRankTableModel(ranks, sort), [sort, ranks]);


    if (ranks.length === 0) return (<InternalServerError errorText={"There are no data"}/>);

    return (
        <Wrapper>
            <colgroup>
                <col width={'3%'}/>
                <col width={'6%'}/>
                <col width={'15%'}/>
                <col width={'10%'}/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
            </colgroup>
            <thead>
            <DetailMasteryTableHeader
                currentSort={sort}
                onClickSort={onClickSort}
                sortData={sortList}
            />
            </thead>
            <TBody>
                {Array.isArray(model.sortedList) && model.sortedList.map((data, index) => {
                    return (
                        <ChampionDetailMasteryTableContent
                            puu_id={data.puu_id}
                            tag={data.riot_id_tag_line}
                            key={data.rank + data.champion_id + data.lane}
                            rank={data.rank}
                            champion_id={data.champion_id}
                            region={data.platform_id}
                            division={data.division}
                            games={data.games}
                            lp={data.lp}
                            play_rate={data.play_rate}
                            riot_id_name={data.riot_id_name}
                            tier={data.tier}
                            win_rate={data.win_rate}
                            summoner_profile_id={data.summoner_profile_id}
                            influData={{
                                status: capitalizeLetter(data.pro_streamer),
                                team: data.team_as,
                                name: data.name,
                            }}
                            lane={data.lane}
                            kda={data.kda}
                            gd15={data.gd}
                            ds={data.ds}
                            rune={data.rune}
                            item={Number(data.item)}
                        />)
                })}
            </TBody>
        </Wrapper>
    );
};


export default ChampionDetailMasteryTable;

