import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SET_ITEM_INFO} from "../../store/reducer/imageStore";
import {useQuery} from "@tanstack/react-query";
import {secretApi} from "../../api/api";

const useItemInfo = () => {
    const dispatch = useDispatch();
    const itemInfo = useSelector((state) => state?.imageStore.itemInfo);

    useQuery(
        {
            queryKey: ['itemInfo'],
            queryFn: async () => {
                const result = await secretApi.get(`${process.env.REACT_APP_CACHE_API}common/item-info`)
                const data = result.data
                dispatch({
                    type: SET_ITEM_INFO,
                    data: data,
                })
                return result
            },
            staleTime: Infinity
        },
    )
    const mythicItem = useMemo(() => {
        try {
            if (Array.isArray(itemInfo.mythic_item_lst)) {
                return itemInfo.mythic_item_lst
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [itemInfo]);
    const supItem = useMemo(() => {
        try {
            if (Array.isArray(itemInfo.sup_item_lst)) {
                return itemInfo.sup_item_lst
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [itemInfo]);

    const isSupItem = (item) => {
        return supItem.includes(item);
    }
    return {
        mythicItem,
        isSupItem,
        supItem
    };
};

export default useItemInfo;