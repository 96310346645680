import React from 'react';
import useQueueId from "./useQueueId";
import {Trans, useTranslation} from "react-i18next";
import {capitalizeLetter, getRegionServer} from "../../function";
import styled from "styled-components";
import useChampionFunc from "./useChampionFunc";

const News6Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const News6Span = styled.span`
  margin-left: 4px;
`;
const UseMarqueeDescription = ({onlyText}) => {
    const {t, i18n} = useTranslation()
    const {getMatchName} = useQueueId();
    const {getChampion} = useChampionFunc();


    const getStatus = (status) => {
        if (status === 'Streamer') {
            return t("autoComplete.streamer")
        } else if (status.toLowerCase() === 'pro') {
            return t("autoComplete.pro")
        }
        return ""
    }

    const transDescription = (data) => {
        const descriptionNumber = Number(data.description);

        switch (descriptionNumber) {

            // t={('news.news02', {
            //     player1: data.name,
            //     player2: data.versus,
            // })}
            case 2:
                return <Trans
                    i18nKey={'news.news02'}
                    values={{
                        player1: data.name,
                        player2: data.versus
                    }}
                />
            case 3:
                return <span>{t('news.news03', {
                    gameMode: getMatchName(data.queue_id)
                })}</span>
            case 4:
                return <span>{t('news.news04', {
                    gameMode: getMatchName(data.queue_id)
                })}</span>
            case 5:
                return <span>{t('news.news05', {
                    grade: t('news.grade', {
                        count: Number(data.versus),
                        ordinal: true,
                    })
                })}</span>
            case 6: {
                const splitTier = data?.versus?.split(" ") ?? ['', ''];
                if (onlyText) {
                    return (<span>{t('news.news06', {
                        tier: `${capitalizeLetter(splitTier[0].toLowerCase())}${splitTier[1] ? ` ${splitTier[1]}` : ""}`
                    })}
                    </span>)
                }
                return <News6Wrapper>
                    <img src={`./images/story/icn_${splitTier[0]}.svg`} alt={''}/>
                    <News6Span>
                        {t('news.news06', {
                            tier: `${capitalizeLetter(splitTier[0].toLowerCase())}${splitTier[1] ? ` ${splitTier[1]}` : ""}`
                        })}
                    </News6Span>
                </News6Wrapper>
            }
            case 7:
                return <span>{t('news.news07')}</span>
            case 8:
                return <span>{data.versus}</span>
            case 9: {
                const split = data.versus.split('/');
                if (split.length === 2) {

                    const champion = split[0]
                    const score = split[1]


                    return t('news.news09', {
                        champion: getChampion(champion).champion_name,
                        score: score
                    })
                }
                return ""
            }
            case 1000:
                return <span>{t('news.news1000', {
                    ai_score: Math.round(data.ai_score),
                    platform_id: getRegionServer(data.platform_id),
                })}</span>
            default:
                return ""
        }
    }


    return {
        transDescription
    }
};

export default UseMarqueeDescription;