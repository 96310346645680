import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {MatchItemContainer} from '../../styled/SummonerStyle';
import styled, {useTheme} from "styled-components";
import {
    getTierScore,
    isAram,
    isArena,
    isDetailChartRender,
    isSwarm,
    noPositionCheck,
    sortChampData
} from "../../function";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import useTimeHook from "../../common/hooks/useTimeHook";
import ColorRankBox from "./ColorRankBox";
import useArenaFunc from "../../common/hooks/useArenaFunc";
import {ColumnBox, FlexBox} from "../ui/styled";
import SmallSummonerList from "./MathItem/SmallSummonerList";
import useQueueId from "../../common/hooks/useQueueId";
import SummonerDailyTag from "./SummonerDailyTag/SummonerDailyTag";
import MatchDetail from "./MatchDetail";
import MatchChampInfo from "./MatchChampInfo";
import MatchRenderChart from "./MatchRenderChart";
import MatchKda from "./MatchKDA";
import MatchScoreWrapper from "./MatchScoreWrapper";
import useDetailChartTab from "../../common/hooks/useDetailChartTab";
import {OPEN_CHART_MODAL} from "../../store/modules/chartModal";


const KDASpan = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${props => props.theme.color.dark_200};
`;

const CSp = styled.p`
  color: ${props => props.theme.color.dark_100};
  opacity: 0.6;
`;
const RuneWrapper = styled.div`
  cursor: pointer;
`;

const AwayText = styled.span`
  opacity: 1;
  color: ${props => props.theme.color.red_300};
`;

const MarginTopDiv = styled.div`
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: ${props => {
    let content = 'space-between';
    if (props.isUrf) content = 'center';
    return content;
  }};
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  margin-left: ${props => props.marginLeft}px;
`;
const RowBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  margin-left: 8px;
  margin-right: 5px;
`;


const LaneBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

// noinspection CssUnknownTarget
const CrownMvp = styled.span`
  display: flex;
  background-image: url('images/ico_best_player01.svg');
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
  content: '';
`;

// noinspection CssUnknownTarget
const CrownAce = styled.span`
  display: flex;
  background-image: url('images/ico_best_player04.svg');
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
  content: '';
`;


const GameTime = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: 400;
`;

const GameTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const Aisort = [{
    value: 'is_win', sortType: false,
}, {
    value: 'ai_score', sortType: false,
}];

const AvgTierWrapper = styled.div`
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  font-weight: 400;
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
`;

const SmallChampImage = styled.div`
  .imgDiv {
    border-radius: ${props => props.borderRadius};
  }
`;

const PlayerMarginBox = styled.div`
  margin-right: 4px;
`;

const DuoWrapper = styled.div`
  margin-left: 6px;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .imgChamp {
    width: 42px;
    height: 42px;
  }
`;
const DuoSpan = styled.span`
  font-size: 11px;
  line-height: 17px;
  margin-bottom: 2px;
  opacity: 0.8;
`;


const ItemBox = styled.div`
  display: flex;
  flex-direction: row;

  .imgItem {
    margin-right: 4.5px;
  }`;

const KDAText = styled.p`
  line-height: 15px; /* 100% */
  margin-bottom: 3px;
  z-index: 2;
  color: ${props => props.theme.color.dark_100};

  span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  }

  .slash {
    color: ${props => props.theme.color.dark_200};
    margin: 0 3px;
  }

  .death {
    color: ${props => props.theme.color.red_300};
  }
`;
const KDAResultBox = styled.p`
  z-index: 2;
  min-width: 84px;
  margin-bottom: 4px;
`;

const SummonerBox = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 16px;
`;
const RenderArenaRank = ({match, myMatch}) => {
    const {getArenaTeam} = useArenaFunc({match});
    const team = getArenaTeam(myMatch);

    return (<ColorRankBox rank={team.placement}/>)
}

const RatingBox = styled(FlexBox)`
  z-index: 2;
  color: ${props => props.theme.color.font_58};

  .MVP {
    color: #F3A634;
    font-weight: 700;
  }

  .ACE {
    font-weight: 700;
    color: #8e9ec4;
  }

`;


const AIScoreText = styled.h5`
  z-index: 2;
  color: ${props => props.theme.color.dark_200};
`;

const HardCarryEffect = styled.div`
  @keyframes loading {
    0% {
      transform: skew(25deg) translateX(740px);
      opacity: 1;
    }
    80% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: skew(25deg);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 100%;
  background: #fff;
  opacity: 0.8;
  animation: loading 2.3s infinite linear;
  filter: blur(37px);
`;


const MatchItem = ({match, openId, handleMoreInfo, region, currentLang, summoner, openButton = true}) => {
    const {getMatchName} = useQueueId();
    const {getArenaTeam} = useArenaFunc({match})
    const {t} = useTranslation();
    const myMatch = useMemo(() => {
        try {
            const list = match?.participants_list
            if (Array.isArray(list)) {
                let item = list.find((data) => data.puu_id === summoner?.summoner_basic_info_dict?.puu_id);
                if (typeof list === 'object') {
                    if (isArena(match.match_basic_dict.queue_id)) {
                        const arena_team = getArenaTeam(item);
                        item = {
                            ...item,
                            arena_team,
                        }
                    }
                    return item
                }
            }
            return {}
        } catch (e) {
            return {}
        }
    },[match,summoner]);
    const dispatch = useDispatch();
    const [openIndex, setOpenIndex] = useState(0);
    const [hide, setHide] = useState(false);
    const theme = useTheme();
    const {originArr} = useDetailChartTab({match,myMatch});


    const onChangeHide = useCallback(() => {
        setHide(prev => !prev);
    }, []);
    const {lessMinWithIntl} = useTimeHook()


    const getAvgTier = useMemo(() => {
        try {
            const playerList = match.participants_list;
            const filterUnrank = playerList.filter((data) => data.tier !== "unranked");
            const combinedPlayerTier = filterUnrank.reduce((sum, cur) => {
                sum += getTierScore(`${cur.tier} ${cur.division}`).num
                return sum;
            }, 0);
            let length = filterUnrank.length;
            if (filterUnrank.length === 0) {
                length = 1;
            }
            return getTierScore(Math.trunc(combinedPlayerTier / length)).obj;
        } catch (e) {
            return {tier: "Unknown", division: 1};
        }
    }, [match]);

    const AiMemo = useMemo(() => match && sortChampData(match.participants_list, Aisort), [match]);
    const HardCarryGap = useMemo(() => {
        if (match) {
            const reducedList = match.participants_list.reduce((sum, data, index) => {

                if (data.is_win) {
                    sum.list.push(data);
                    if (sum.list.length > 0) {
                        sum.aiScoreAvg = Math.trunc(sum.list.reduce((sum, cur) => sum + cur.ai_score, 0) / sum.list.length)
                    } else {
                        sum.aiScoreAvg = data.ai_score;
                    }
                }
                return sum;
            }, {list: [], aiScoreAvg: 0,});


            const aiScoreList = reducedList.list.reduce((sum, cur) => {
                sum.push(cur.ai_score);
                return sum;
            }, []);
            const getHighestAiScore = Math.max(...aiScoreList);
            const findIndex = aiScoreList.findIndex((data) => data === getHighestAiScore);

            const getAvg = aiScoreList.reduce((sum, cur, index) => {
                if (index === findIndex) {
                    return sum;
                }

                sum.list.push(cur);
                if (sum.list.length > 0) {
                    sum.aiScoreAvg = Math.trunc(sum.list.reduce((sum, cur) => sum + cur, 0) / sum.list.length)
                } else {
                    sum.aiScoreAvg = cur;
                }

                return sum;
            }, {
                list: [], aiScoreAvg: 0,
            })


            return {
                ...reducedList.list[findIndex], gap: getHighestAiScore - getAvg.aiScoreAvg,
            }
        }
        return {
            riot_id_name: "", gap: 0,
        }
    }, []);


    const isHardCarry = useCallback(() => {
        if (isArena(match.match_basic_dict.queue_id)) {
            return false
            // const team = myMatch.arena_team
            // console.log(team)
            // if (team.placement === 1) {
            //     const winningStreak = getWinningStreak(team.round_seq?.value)
            //     const lastWinningStreak = getLastWinningStreak(team.round_seq?.value)
            //     if (lastWinningStreak > 6) {
            //         return true;
            //     }
            //
            //     const winRate = (team.win / (team.win + team.lose) * 100).toFixed(1)
            //     if (winRate >= 80 && winningStreak > 3) {
            //         return true;
            //     }
            // }
            // return false;
        }

        if (isSwarm(match.match_basic_dict.queue_id)) {
            return false;
        }


        if (AiMemo[0].riot_id_name) {
            if (AiMemo[0].riot_id_name === HardCarryGap.riot_id_name && HardCarryGap.riot_id_name === myMatch.riot_id_name) {
                return AiMemo[0].ai_score >= 75 && AiMemo[0].is_win && HardCarryGap.gap >= 25;
            }
        } else {
            if (AiMemo[0].summoner_name === HardCarryGap.summoner_name && HardCarryGap.summoner_name === myMatch.summoner_name) {
                return AiMemo[0].ai_score >= 75 && AiMemo[0].is_win && HardCarryGap.gap >= 25;
            }
        }


        return false;
    }, [AiMemo, HardCarryGap, myMatch]);


    useEffect(() => {
        setHide(false);
    }, [openId]);


    const onClickGraph = useCallback((e) => {
        e.stopPropagation();
        setOpenIndex(1);
        handleMoreInfo(match.match_basic_dict.match_id)
    }, [match, handleMoreInfo]);

    const onClickOpen = useCallback((e) => {
        setOpenIndex(0);
        handleMoreInfo(match.match_basic_dict.match_id)
    }, [match, handleMoreInfo])

    const onClickRankGraph = useCallback((e, id, closeable) => {
        if (!isDetailChartRender(match)) {
            return;
        }
        e.stopPropagation();

        const info = match.participants_list.find((data) => data.puu_id === id);

        if(info) {
            dispatch({
                type: OPEN_CHART_MODAL,
                open: true,
                modalData: {originArr,modalIndex:0,},
                info: info,
                match: match,
            })
        }



    }, [match, handleMoreInfo]);


    const setHms = useCallback((time) => {
        const hour = time > 3600 ? time / 3600 : 0;
        const min = time / 60;
        const sec = time % 60;
        return `${hour}:${min}:${sec}`;

    }, []);


    const renderGameTime = useCallback((time) => {
        let hour = time > 3600 ? Math.floor(time / 3600) : 0;
        let min = Math.floor((time - (hour * 3600)) / 60);
        let sec = Math.floor(time % 60);

        const renderMin = min > 9 ? min : `0${min}`;
        const renderSec = sec > 9 ? sec : `0${sec}`


        if (hour > 0) {
            return `${hour}:${renderMin}:${renderSec}`;
        } else {
            return `${renderMin}:${renderSec}`
        }
    }, []);


    const aiSorted = useMemo(() => {
        return sortChampData(match?.participants_list, [{value: "ai_score", sortType: false}]);
    }, [match, myMatch]);


    const getRank = useMemo(() => {
        if (Array.isArray(match?.participants_list)) {
            const index = aiSorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index + 1;
        }
        return 0;
    }, [match, myMatch, aiSorted]);


    const getMvp = useMemo(() => {
        if (Array.isArray(match?.participants_list)) {
            const filteredTeam = match?.participants_list.filter((data) => data.side === myMatch.side);
            const sorted = sortChampData(filteredTeam, [{value: "ai_score", sortType: false}]);
            const index = sorted.findIndex((data) => data.puu_id === myMatch.puu_id);
            return index === 0;
        }
    }, [match, myMatch]);


    const RatingRender = useCallback(({match, rating}) => {
        if (!match.is_runaway) {
            if (getMvp) {
                const specialChar = match?.is_win ? 'MVP' : 'ACE';
                return <span className={specialChar}>{specialChar}</span>
            }
            return (<>
                <i>{rating}</i>{t('summoner.grade', {count: rating, ordinal: true})}
            </>)
        }

        return (<AwayText>{t("tags.escape")}</AwayText>)

    }, [getMvp])

    const onClickToBuild = useCallback((e) => {
        e.stopPropagation();
        if(isDetailChartRender(match)) {
            setOpenIndex(2);
        } else {
            setOpenIndex(1)
        }
        handleMoreInfo(match.match_basic_dict.match_id)
    }, [handleMoreInfo]);


    const getContainerClassName = () => {
        if (isHardCarry()) {
            return 'hardcarry'
        }
        return myMatch.is_win ? 'win' : 'lose'
    }

    const getArrowName = () => {
        return myMatch.is_win ? 'icon_arrow_win.svg' : 'icon_arrow_lose.svg'
    }


    const getGameTypeText = () => {
        if (match.remake) {
            return 'remake'
        }

        if (isHardCarry()) {
            return "hardcarry"
        }
        if (myMatch.is_win) {
            return "win"
        } else {
            return 'lose'
        }
    }


    return (
        <SummonerDailyTag isWin={myMatch.is_win} isCarry={isHardCarry()} open={openId}
                          matchId={match?.match_basic_dict?.match_id}>
            <MatchItemContainer>
                {typeof myMatch?.champion_id === 'number' && (<div
                        className={`rowContainer ${match.remake ? 'remake' : myMatch.is_win ? 'win' : 'lose'} ${isHardCarry() ? 'hardcarry' : ""} ${openId ? 'open' : 'close'}`}
                    >
                        <div className="row" onClick={onClickOpen}>
                            <div className="col flex_column0">
                            </div>
                            <div className="col flex_column1">
                                <ColumnBox height={'auto'} padding={'10px 0 0 10px'}>
                                    <h5 className={`${getGameTypeText()}`}>
                                        {getMatchName(match.match_basic_dict.queue_id,match?.match_basic_dict?.tournament_name)}
                                    </h5>
                                    <p>
                                        {lessMinWithIntl((Number(match.match_basic_dict.creation_timestamp) + Number(match.match_basic_dict.game_duration)) * 1000, currentLang)}
                                    </p>
                                    <GameTimeWrapper>
                                        <dt>
                              <span>
                                {match.remake ? t('summoner.remake') : myMatch.is_win ? t('summoner.win') : t('summoner.lose')}
                              </span>
                                        </dt>
                                        <GameTime>
                                            {renderGameTime(Number(match.match_basic_dict.game_duration))}
                                        </GameTime>
                                    </GameTimeWrapper>
                                    <AvgTierWrapper>{getAvgTier?.tier} {getAvgTier.division}</AvgTierWrapper>
                                </ColumnBox>
                            </div>
                            <div className="col flex_column2">
                                <ColumnBox height={'auto'}>
                                    <FlexBox height={'auto'} alignItems={'flex-start'}>
                                        <dl>
                                            <dt className={`${(!isArena(match.match_basic_dict.queue_id) && myMatch.is_runaway) ? 'away' : 'home'}`}>
                                                <ChampionImageWithLane
                                                    disableImage={!noPositionCheck(match.match_basic_dict.queue_id,match.match_basic_dict.map_id)}
                                                    champion_id={myMatch.champion_id}
                                                    position={myMatch.position}
                                                    fullImage={true}
                                                />
                                            </dt>
                                                <MatchChampInfo match={match} myMatch={myMatch} onClickToBuild={onClickToBuild}/>
                                        </dl>
                                        <MatchKda match={match} myMatch={myMatch}/>
                                    </FlexBox>
                                    <FlexBox height={'auto'} margin={'6px 0 0 0'}>
                                        <ItemBox>
                                            <MatchRenderChart match={match} myMatch={myMatch} aiSorted={aiSorted}
                                                              onClickGraph={onClickGraph}
                                                              onClickToBuild={onClickToBuild}
                                            />
                                        </ItemBox>
                                    </FlexBox>
                                </ColumnBox>
                                <MatchScoreWrapper match={match} myMatch={myMatch} getMvp={getMvp}
                                                   RatingRender={RatingRender} getRank={getRank}
                                                   onClickRankGraph={onClickRankGraph}
                                                   />
                            </div>
                            <SummonerBox className="flex_column6">
                                <SmallSummonerList match={match} myMatch={myMatch} hide={hide} region={region}
                                                   queueId={match.match_basic_dict.queue_id}/>
                            </SummonerBox>
                            <div className="col flex_column7">
                                {openButton && <button onClick={onClickOpen}>
                                    <img
                                        src={`images/${getArrowName()}`}
                                        alt={t('buttons.detail')}/>
                                </button>}
                            </div>
                            {isHardCarry() && <HardCarryEffect/>}
                        </div>
                    </div>
                )
                }
                {/*{openId && <SummonerDetailAd/>}*/
                }
            </MatchItemContainer>

            {
                openId && <MatchDetail
                    isUrf={(match.match_basic_dict.queue_id === 900 || isAram(match.match_basic_dict.queue_id,match?.match_basic_dict?.map_id) || isArena(match.match_basic_dict.queue_id))}
                    region={region}
                    summoner={summoner}
                    match={match} myMatch={myMatch}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                    hide={hide}
                    onChangeHide={onChangeHide}
                    queueId={match.match_basic_dict.queue_id}
                    onClickRankGraph={onClickRankGraph}
                />
            }
        </SummonerDailyTag>
    );
};


const isEquals = (prev, next) => {
    return prev.match?.gameId === next.match?.gameId && prev.openId === next.openId
}
export default React.memo(MatchItem, isEquals);
