import axios from "axios";


const developmentApi = axios.create({
    headers: {
        "X-DEEPLOL-SECRET": process.env.REACT_APP_DEEPLOL_SECRET
    }
})

const publicApi = axios.create({})

export const secretApi = process.env.REACT_APP_DEEPLOL_SECRET === 'false' ? publicApi : developmentApi
