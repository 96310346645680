import React, {useMemo} from 'react';
import styled from "styled-components";
import InfluIcon from "./InfluIcon";
import SmallInfluInfo from "../../../common/SmallInfluInfo";
import SnsImage from "../../../common/SnsImage";
import {useHistory} from "react-router-dom";
import NewTabLink from "../../../Link/NewTabLink";

const InfluWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: center;

  img {
    margin-left: ${props => {
      let margin = '0px';
      if (props.direction === 'row-reverse') margin = '5px';
      return margin;
    }};

    margin-right: ${props => {
      let margin = '4px';
      if (props.direction === 'row-reverse') margin = '0px';
      return margin;
    }};
  }
`;


const InfluName = styled.span`
  display: inline;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: ${props => {
    if (props.color) return props.color;
    return props.theme.color.dark_200
  }};
  text-overflow: ellipsis;
  max-width: ${props => props.maxWidthText}px;
  overflow: hidden;
  cursor: pointer;
`;


const InfluNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;


  background-color: ${props => {
    let color = 'transparent';
    if (props.highlight) color = props.theme.color.white_201
    return color;
  }};
  padding: ${props => {
    let padding = 0;
    if (props.highlight) padding = '5px';
    return padding
  }};
  border-radius: 6px;

  ${props => {
    if (props.disableHover) {
      return null
    }
    return `
  &:hover {
    ${InfluName} {
      text-decoration: underline;
    }
  }
      `
  }}
`;

const SnsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > .imgBox {
  }
`;

const HomeBox = styled.div`
  cursor: pointer;
  margin-left: 4px;

  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
  }
`;

const InfluInfo = ({
                       influData,
                       visibleHome,
                       textColor,
                       showLinks = true,
                       direction = 'row',
                       maxWidthText,
                       smallIcon,
                       disableHover,
                       disableClick,
                       highlight,
                   }) => {
    const history = useHistory();


    const nameUrl = useMemo(() => {
        if (influData?.status?.toLowerCase() === 'pro' && influData.url_name) {
            return `/pro/${influData.url_name}`
        } else if (influData?.status?.toLowerCase() === 'streamer') {
            return `/strm/${influData.name}`
        } else {
            console.log('error', influData);
            return ""
        }
    }, [influData])

    if (!influData?.status) return null;
    if (influData?.status === '-') return null
    return (
        <InfluWrapper direction={direction} className={'influ-wrapper'}>
            {/*{influData.status === "pro" && <Protag><TagText>Pro</TagText></Protag>}*/}
            {/*{influData.status === "Streamer" && <StreamerTag><TagText>Streamer</TagText></StreamerTag>}*/}
            <ConditionalLink disabled={disableClick || !nameUrl} to={nameUrl}>
                <InfluNameWrapper  disableHover={disableHover} highlight={highlight}>
                    {smallIcon ? <SmallInfluInfo status={influData?.status}/> :
                        <InfluIcon status={influData?.status}/>}
                    <InfluName className={'influ-name'} maxWidthText={maxWidthText}
                               color={textColor}>{influData.team ? `${influData.team} ` : ""}{influData.name}</InfluName>
                </InfluNameWrapper>
            </ConditionalLink>
            {visibleHome &&
                <ConditionalLink disabled={disableClick || !nameUrl} to={nameUrl}>
                    <HomeBox >
                        <img src={'/images/icn_sns_home.svg'} width={24} height={24} alt={""}/>
                    </HomeBox>
                </ConditionalLink>}
            {showLinks &&
                <SnsWrapper>
                    <SnsImage influData={influData}/>
                </SnsWrapper>}
        </InfluWrapper>
    );
};


const ConditionalLink = ({disabled, to, children}) => {
    if (disabled) {
        return children
    }

    return <NewTabLink to={to}>
        {children}
    </NewTabLink>
}

export default InfluInfo;
