import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {FooterContainer} from '../../styled/CommonStyle';
import styled, {useTheme} from "styled-components";
import {FlexBox} from "../ui/styled";


const FeedbackA = styled.a`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
`;
const FeedBackLink = styled(Link)`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
`;
const Feedback = styled.button`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
`;


const OwnerText = styled.span`
  font-weight: 400;
  font-size: 10px;
`;

const ContentWrapper = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const CopyRightText = styled.span`
  font-size: 10px;
  font-weight: bold;
  margin-left: 20px;
`;
const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 780px;
  margin-bottom: 15px;
`;
const LinkItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const Footer = () => {
    const [openFeedback, setOpenFeedback] = useState(false);
    const {t} = useTranslation();
    const [isToggled, setIsToggled] = useState(false);
    const theme = useTheme();
    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isToggled && (!e.current || !e?.current?.contains(e.target))) setIsToggled(false);
        },
        [isToggled]
    );

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);


    return (
        <FooterContainer id={'footer-container'}>
            <ContentWrapper>
                <LinkWrapper>
                    <LinkItem><Link to={'/privacy'}>{t('footer.policy')}</Link></LinkItem>
                    <LinkItem><a href={'https://deeplol.atlassian.net/servicedesk/customer/portal/1/group/1/create/39'} target={'_blank'}>{t('footer.contact')}</a></LinkItem>
                    <LinkItem><a
                        href={'https://deeplol.atlassian.net/servicedesk/customer/portal/1/group/1/create/40'} target={'_blank'}>{t('footer.pro')}</a></LinkItem>
                    <LinkItem><Link to={'/advertise'}>{t('footer.advertise')}</Link></LinkItem>
                </LinkWrapper>
                <div className="f__copy">
                    <dl>
                        <dd>
                            deeplol.gg isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot
                            Games or anyone officially involved in producing or managing League of Legends.{'\n'}
                            League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc.
                            League of Legends © Riot Games, Inc.{'\n'}
                            (주)게임아이 대표: 윤덕진 사업자등록번호: 715-81-01319{'\n'}
                        </dd>
                    </dl>
                </div>

                <FlexBox margin={'5px 0 0 0'}>
                    <img src={`${theme.icon.path}/icon_gameeye_logo_w.svg`} alt="GAME EYE" width={124} height={20}/>
                    <CopyRightText>Copyright © GameEye Corp. All rights reserved.</CopyRightText>
                </FlexBox>
            </ContentWrapper>
        </FooterContainer>
    );
};

export default Footer;
