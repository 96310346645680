import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import {Header} from "../../../common";
import CenterBox from "../../../common/CenterBox";
import {FlexBox} from "../../../ui/styled";
import SpectateLiveCompound from "../compound";
import {useSelector} from "react-redux";
import {sortLaneMap, useTitle} from "../../../../function";
import {useTranslation} from "react-i18next";
import useAdHooks from "../../../../common/hooks/useAdHooks";
import {center_content} from "../../../../ad/bidrich";
import UseSetCanonical from "../../../../common/hooks/useSetCanonical";
import BottomStickAd from "../../../ad/BottomStickAd";


const Wrapper = styled.div`
  min-height: 90vh;
`;

const BorderBox = styled(FlexBox)`
  display: flex;
  flex-direction: column;
  width: 740px;
  border: 1px solid ${props => props.theme.color.default_border_color};


  & + & {
    margin-top: 6px;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;
const SpectateLivePro = () => {
    UseSetCanonical();

    const theme = useTheme();
    useAdHooks(center_content);
    const [openId, setOpenId] = useState(null);
    const {t} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    setTimeout(() => titleUpdate(t('header.titleLive')), 500);

    const {
        live,
    } = useSelector((state) => state.spectate);


    const liveList = useMemo(() => {
        try {
            return live.spectator_data_list;
        } catch (e) {
            return [];
        }
    }, [live]);


    const getSummaryPlayers = useCallback((item) => {
        try {
            const list = item.ingame_data_list
            const player1 = item.player1_dict;
            const player2 = item.player2_dict;
            const findPlayer1 = list.find((data) => data.name === player1.name);
            const findPlayer2 = list.find((data) => data.name === player2.name);
            return {
                player1: {
                    ...player1,
                    riot_id_name: findPlayer1.riot_id_name,
                    riot_id_tag_line: findPlayer1.riot_id_tag_line
                },
                player2: {
                    ...player2,
                    riot_id_name: findPlayer2.riot_id_name,
                    riot_id_tag_line: findPlayer2.riot_id_tag_line
                }
            }
        } catch (e) {
            return {
                player1: {},
                player2: {},
            }
        }
    }, [live]);


    const teamSplit = useCallback((item) => {
        try {
            const list = sortLaneMap(item.ingame_data_list);
            return {
                leftTeam: list.filter((data) => {
                    if (item.player1_dict.team_id) {
                        return Number(item.player1_dict.team_id) === Number(data.team_id);
                    } else {
                        return Number(item.player2_dict.team_id) !== Number(data.team_id);
                    }

                }),
                rightTeam: list.filter((data) => {
                    if (item.player2_dict.team_id) {
                        return Number(item.player2_dict.team_id) === Number(data.team_id);
                    } else {
                        return Number(item.player1_dict.team_id) !== Number(data.team_id);
                    }

                }),
            }
        } catch (e) {
            return {
                leftTeam: [],
                rightTeam: [],
            }
        }
    }, []);


    const onClickOpenId = (index) => {
        setOpenId(prev => prev === index ? null : index);
    }

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <Wrapper>
            <Header/>
            <CenterBox disableExtraHeader={true}>
                <SpectateLiveCompound>
                    <SpectateLiveCompound.title/>
                    <ItemWrapper>
                        {liveList.map((data, index) => {
                            const summaryPlayer = getSummaryPlayers(data);
                            const {leftTeam, rightTeam} = teamSplit(data);
                            return (
                                <BorderBox height={'auto'} alignItems={'flex-start'} key={index}
                                           onClick={() => onClickOpenId(index)}>
                                    <SpectateLiveCompound.summary
                                        isOpen={openId === index}
                                        mostLikedPlayer={summaryPlayer.player1}
                                        player1={summaryPlayer.player1}
                                        player2={summaryPlayer.player2}
                                        encryptKey={data.encryptionKey}
                                        queueId={data.queue_id}
                                        startTime={data.start_timestamp}
                                        matchId={data.match_id}
                                        platformId={data.platform_id}/>
                                    {openId === index &&
                                        <FlexBox height={'auto'} alignItems={'flex-start'} width={'100%'}
                                                 background={theme.color.site_main_color}>
                                            <SpectateLiveCompound.summoner list={leftTeam}
                                                                           platformId={data.platform_id}/>
                                            <SpectateLiveCompound.summoner reverse={true} list={rightTeam}
                                                                           platformId={data.platform_id}/>
                                        </FlexBox>}
                                </BorderBox>
                            )
                        })}
                    </ItemWrapper>
                </SpectateLiveCompound>
            </CenterBox>
            <BottomStickAd/>
        </Wrapper>
    );
};

export default SpectateLivePro;