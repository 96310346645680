import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import styled, {useTheme} from "styled-components";
import {isArena, isSwarm, sortChampData} from "../../../../function";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import useQueueId from "../../../../common/hooks/useQueueId";
import Dropdown from "../../../compound/Dropdown";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {useTranslation} from "react-i18next";
import {dark, light} from "../../../../common/theme";


const Box = styled.button`
  display: flex;
  flex-direction: row;
  padding: 7px 10px;
  margin-right: 6px;
  border: 1px solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-radius: 5px;
  font-size: 12px;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

  &:hover {
    color: ${props => {
      let color = props.theme.color.dark_200;
      if (props.selected) color = props => props.theme.color.green_100;
      return color;
    }};
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  font-weight: ${props => {
    let weight = 500;
    if (props.selected) weight = 'bold';
    return weight
  }};
`;

const QueueButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  width: 140px;
  height: 28px;
  align-items: center;
  line-height: 20px;
  margin-right: 6px;
  border: 1px solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  background: ${props => `url("${props.theme.icon.path}/icon_queueType_down_arrow.svg")`} no-repeat;
  background-position: calc(100% - 10px) center !important;
  border-radius: 5px;
  font-size: 12px;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

  &:hover {
    color: ${props => {
      let color = props.theme.color.dark_200;
      if (props.selected) color = props => props.theme.color.green_100;
      return color;
    }};
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  font-weight: ${props => {
    let weight = 500;
    if (props.selected) weight = 'bold';
    return weight
  }};
`;
const CountSpan = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  margin-left: 12px;
`;
const Xspan = styled.span`
  font-size: 11px;
  position: absolute;
  left: -6px;
  bottom: 0.5px;
`;
const ChampBox = styled.button`
  margin-right: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity
  }};


  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .imgDiv {
    border-radius: 7px;
  }
`;
const Wrapper = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
`;
const QueueBox = styled.div`
  display: flex;
  flex-direction: row;

`;

const DropdownAbsolute = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  position: absolute;
  border-radius: 6px;
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  margin-top: 2px;

`;

const QueueDropdownAbsolute = styled(DropdownAbsolute)`
  width: 140px;
  background: ${props => props.theme.color.site_main_color};

  .dropdown-item + .dropdown-item {
    border-top: 1px solid ${props => props.theme.color.gray_200};
  }
`;
const SearchDropdownAbsolute = styled(DropdownAbsolute)`
  width: 200px;
  background: ${props => props.theme.color.site_main_color};
`;

const ScrollBox = styled.div`
  width: 100%;
  max-height: 250px;
  overflow-y: auto;

  .light + .light {
    border-top: 1px solid ${props => light.gray_200};
  }

  .dark + .dark {
    border-top: 1px solid ${props => dark.gray_200};
  }
`;

const DropdownItem = styled.button`
  color: ${props => props.theme.color.dark_100};
  background: ${props => props.theme.color.site_main_color};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  font-size: 12px;


  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;
const QueueDropdownItem = styled(DropdownItem)`
  font-weight: 500;
  padding: 0 10px;
`;
const SearchDropdownItem = styled(DropdownItem)`
  font-weight: 500;
  padding: 0 5px;


  .imgChamp {
    width: 30px;
    height: 30px;
  }

  .imgDiv {
    border-radius: 6px;
  }


`;

const ChampionSearchInput = styled.input`
  display: flex;
  flex-direction: row;
  padding: 0 30px 0 10px;
  width: 200px;
  height: 28px;
  align-items: center;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};

  background: ${props => `url("${props.theme.icon.path}/ico_input_serach.svg")`} no-repeat;
  background-color: ${props => props.theme.color.white_201};
  background-position: calc(100% - 10px) center !important;

  &&::placeholder {
    color: ${props => props.theme.color.dark_200};
  }
`;
const RelativeBox = styled.div`
  position: relative;
`;

const ChampionName = styled.span`
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  margin-left: 5px;
`;

const OverviewSortFilter = ({queue, selectedChampionId, onChangeQueue, onChangeChampionId, match,queueFilterMatch}) => {
    const {t} = useTranslation()
    const matchInfo = useSelector((state) => state.summoner.match);
    const summoner = useSelector((state) => state.summoner.summoner);
    const {getMatchName} = useQueueId();
    const {getChampion} = useChampionFunc()
    const [inputValue, setInputValue] = useState("");
    const [focus, setFocus] = useState(false);
    const absoluteRef = useRef(null);
    const theme = useTheme();
    const matchSortValue = useCallback((queueId) => {
        switch (queueId) {
            case 100:
                return 1;
            case 0 :
                return 9;
            case 400:
                return 11;
            case 420 :
                return 2;
            case 430 :
                return 4;
            case 490:
                return 4;
            case 440 :
                return 3;
            case 450 :
                return 5;
            case 900 :
                return 6;
            case 1400:
                return 10;
            case 1900 :
                return 7;
            case 700 :
                return 8;
            case 720 :
                return 9
            default:
                return queueId;
        }
    }, []);

    const visibleValue = (queueId) => {
        switch (queueId) {
            case 100:
                return 1;
            case 420 :
                return true;
            case 490 :
                return true;
            case 440 :
                return true;
            case 450 :
                return true;
            default:
                return false;
        }
    }

    const matchTypeList = useMemo(() => {
        try {
            const obj = {100: matchInfo.length}
            matchInfo.forEach((data) => {
                let queueId = data.match_basic_dict.queue_id;
                if (isSwarm(queueId)) {
                    queueId = 3000;
                }
                if(isArena(queueId)) {
                    queueId = 1700
                }
                if (obj[queueId]) {
                    obj[queueId]++;
                } else {
                    obj[queueId] = 1;
                }
            });
            const mapped = Object.entries(obj).map((data) => {
                return {
                    sortValue: matchSortValue(Number(data[0])),
                    queue: data[0],
                    count: data[1],
                    visible: visibleValue(Number(data[0]))
                }
            });
            return sortChampData(mapped, [{value: 'count', sortType: false}, {value: 'sortValue', sortType: true}])
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [matchInfo]);


    const matchVisibleList = useMemo(() => {
        try {
            return matchTypeList.filter((data) => data.visible);
        } catch (e) {
            return []
        }
    }, [matchTypeList]);


    const matchUnVisibleList = useMemo(() => {
        try {
            const filtered = matchTypeList.filter((data) => !data.visible);
            if (filtered.length > 0) {
                return [{sortValue: 999, queue: 999, count: 0, visible: false}, ...filtered]
            }
            return []
        } catch (e) {
            return []
        }
    }, [matchTypeList]);

    const ChampionList = useMemo(() => {
        try {
            const regexp = new RegExp(`${inputValue.toLowerCase()}`)
            const myPuuId = summoner.summoner_basic_info_dict?.puu_id;
            const reduced = queueFilterMatch.reduce((sum, cur) => {
                const me = cur.participants_list.find((item) => item.puu_id === myPuuId);
                const champion = getChampion(me.champion_id)

                if (!regexp.test(champion?.champion_name?.toLowerCase() || !regexp.test(champion.champion_name_en?.toLowerCase()))) {
                    return sum;
                }

                if (sum?.[me.champion_id]?.value) {
                    sum[me.champion_id].value += 1;
                } else {
                    sum[me.champion_id] = {
                        champion_id: me.champion_id,
                        value: 1,
                        name: champion.champion_name,
                        createdAt: cur.match_basic_dict.creation_timestamp,
                    };
                }
                return sum;
            }, {});
            const array = sortChampData(Object.values(reduced), [{value: 'value', sortType: false}, {
                value: 'createdAt', sortType: true,
            }]);
            return array;
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [matchInfo, queue, inputValue]);


    const onChangeSelect = (value) => {
        if (Number(value) === 999) {
            onChangeQueue(100)
        } else {
            onChangeQueue(Number(value))
        }
    }

    const queueTypeSelectedItem = useMemo(() => {
        try {
            const item = matchUnVisibleList.find((data) => Number(data.queue) === queue);
            if (!item) {
                return 999
            }
            return item.queue
        } catch (e) {
            return ""
        }
    }, [queue])


    const onChangeFocusOut = (e) => {
        if (absoluteRef.current && !absoluteRef?.current?.contains(e.target)) {
            setFocus(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", onChangeFocusOut, true)
        return () => {
            document.removeEventListener("mousedown", onChangeFocusOut, true)
        }
    }, []);

    return (<Wrapper>
        <QueueBox>

            {matchVisibleList.map((data, index) => {
                return (
                    <Box key={index}
                         selected={Number(data.queue) === queue}
                         onClick={() => onChangeQueue(Number(data.queue))}>{getMatchName(Number(data.queue))}</Box>
                )
            })}
            {matchUnVisibleList.length > 0 &&
                <RelativeBox>
                    <Dropdown>
                        <Dropdown.Button>
                            <QueueButton
                                value={queue}
                                selected={matchUnVisibleList.some((data) => Number(data.queue) === queue)}>
                                {getMatchName(Number(queueTypeSelectedItem))}
                            </QueueButton>
                        </Dropdown.Button>
                        <Dropdown.List>
                            <QueueDropdownAbsolute>
                                {matchUnVisibleList.map((data, index) => {
                                    return (
                                        <Dropdown.Item key={index}>
                                            <QueueDropdownItem
                                                onClick={() => onChangeSelect(data.queue)}>{getMatchName(Number(data.queue))}</QueueDropdownItem>
                                        </Dropdown.Item>
                                    )
                                })}
                            </QueueDropdownAbsolute>
                        </Dropdown.List>
                    </Dropdown>
                </RelativeBox>
            }

            {/*
        {ChampionList.map((data, index) => {
            return (
                <ChampBox key={index}
                          selected={data.champion_id === selectedChampionId || selectedChampionId === 0}
                          onClick={() => onChangeChampionId(data.champion_id)}
                          style={
                              index === 0 ? {marginLeft: 4} : {}
                          }
                >
                    <ChampionImageWithLane champion_id={data.champion_id} disabled={true} count={data.value}/>
                </ChampBox>
            )
        })}
*/}
        </QueueBox>
        <RelativeBox ref={absoluteRef}>
            <ChampionSearchInput value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                                 placeholder={t('champions.searchChampion')}
                                 onFocus={() => setFocus(true)}
            />
            {(focus && ChampionList.length > 0) &&
                <SearchDropdownAbsolute>
                    <ScrollBox>

                        {ChampionList.map((data, index) => {
                            return (
                                <SearchDropdownItem key={index} onClick={() => {
                                onChangeChampionId(data.champion_id)
                                    setFocus(false)
                                }} className={theme.color.theme}>

                                    <ChampionImageWithLane
                                        champion_id={data.champion_id}
                                    />
                                    <ChampionName>
                                        {data.name}
                                    </ChampionName>
                                </SearchDropdownItem>
                            )
                        })}

                    </ScrollBox>
                </SearchDropdownAbsolute>}
        </RelativeBox>
    </Wrapper>)
        ;
};

export default OverviewSortFilter;