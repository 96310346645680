import React from 'react';
import styled from "styled-components";
import ChampionsAramHeader from "../../common/ChampionsAndAramHeader";
import CheckDataLayer from "../../DataLayer/CheckDataLayer";
import LeftRailAd from "../../ad/LeftRailAd";
import RightRailAd from "../../ad/RightRailAd";
import VenatusAd from "../../ad/VenatusAd";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${props => props.theme.color.site_background_color};
  border-radius: 6px;
  z-index: 1;
`;

const ContentDiv = styled.div`
  display: flex;
  width: 1080px;
  flex-direction: ${props => {
    let flexD = 'row';
    if (props.flexD) flexD = props.flexD;
    return flexD;
  }};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10vh;
  z-index: 1;
`;

const HeaderAdBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 970px;
  height: 250px;
  margin-top: 15px;
  margin-bottom: 1px;
`;

const AdBox = styled.div`
  flex: 1;
`;


const SmallRail = styled.div`
  width: 300px;
  margin-bottom: 15px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
`;


const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;


const RightSIdeStickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const StickyBoxWrapper = styled.div`
  width: 340px;
  padding: 20px;
  position: relative;
`

const CenterBox = ({children, flexD, disableExtraHeader = false, disableBottomAd}) => {

    return (
        <CheckDataLayer>

            <Wrapper>
                <LeftRailAd/>
                <ContentWrapper>
                    {!disableBottomAd &&
                        <HeaderAdBox id={'desktop-takeover'}>
                            <VenatusAd placementName={'desktop_takeover'}/>
                        </HeaderAdBox>}
                    <CenterDiv id={'content-container'}>
                        {!disableExtraHeader && <ChampionsAramHeader/>}
                        <ContentDiv flexD={flexD}>
                            {children}
                        </ContentDiv>
                    </CenterDiv>
                    {!disableBottomAd && <HeaderAdBox id={'billboard'}>
                        <VenatusAd placementName={'billboard'}/>
                    </HeaderAdBox>}
                </ContentWrapper>
                <RightRailAd/>
            </Wrapper>
        </CheckDataLayer>
    );
};


export default CenterBox;
