import React from 'react';
import styled from "styled-components";
import {useTitle} from "../../function";
import {useTranslation} from "react-i18next";
import {Header} from "../common";
import URFContent from "./urfContent";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import BottomStickAd from "../ad/BottomStickAd";


const Wrapper = styled.div`
`;


const URF = () => {
    UseSetCanonical();

    const {t} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    setTimeout(() => titleUpdate(t('header.titleUrf')), 100);

    // useAdHooks(champion_center_content);


    return (
        <Wrapper>
            <Header/>
            {/*<NotFound/>*/}
            {/*<AramContent/>*/}
            <URFContent/>
            <BottomStickAd/>

        </Wrapper>
    );
};

export default URF;
