import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {GET_SPECTATE_LIVE_FAILURE, GET_SPECTATE_LIVE_REQUEST, GET_SPECTATE_LIVE_SUCCESS} from "../reducer/spectate";
import {secretApi} from "../../api/api";

const getSpectateLiveData = () => {
    const url = `${process.env.REACT_APP_CACHE_API}ingame/spectator`;
    return secretApi.get(url).then((result) => result.data);
}


function* getSpectateLive() {

    try {
        const getData = yield call(getSpectateLiveData);

        yield put({
            type: GET_SPECTATE_LIVE_SUCCESS,
            data: getData
        })
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_SPECTATE_LIVE_FAILURE,
            error: e,
        })
    }
}

function* watchGetSpectateLive() {
    yield takeLatest(GET_SPECTATE_LIVE_REQUEST, getSpectateLive)
}


export default function* spectateSaga() {
    yield all([
        fork(watchGetSpectateLive),
    ])
}
