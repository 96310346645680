import React, {useCallback, useState} from 'react';
import styled, {useTheme} from "styled-components";
import IngameProvider from "../compound/Ingame";
import {ErrorBoundary} from "react-error-boundary";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useSummonerTitleData from "../../common/hooks/useSummonerTitleData";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";


const TableWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  border-left: 1px solid ${props => props.theme.color.default_border_color};
  border-right: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  overflow: hidden;
`;



const IngameWrapper = styled.div`
  margin-top: 10px;
`;

const Ingame = ({region}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {currentUserName,currentTagName,currentProName,} = useSummonerTitleData();
    useTitleAndDescription({
        title: t("header.titleSummonerIngame",{
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
        }),
        description: t("header.titleSummonerIngameDescription",{
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
        }),
    });
    const {ingame} = useSelector((state) => state.summoner);
    const [open, setOpen] = useState("");

    const onClickMore = useCallback((summonerId) => {
        setOpen(prev => {
            if (prev === summonerId) {
                return ""
            } else {
                return summonerId;
            }
        });
    }, []);


    return (
        <ErrorBoundary fallback={<>error...</>}>
            <IngameWrapper>
                <IngameProvider region={region}>
                    {/*{isRanked(ingame?.basic?.queue_id) &&*/}
                    {/*<IngameProvider.AIitem/>}*/}
                    <TableWrapper>
                        <IngameProvider.Overview/>
                        <IngameProvider.PlayerTable
                            region={region}
                            open={open}
                            onClickMore={onClickMore}
                            teamColor={theme.color.blue_100}
                            teamName={t("ingame.blueTeam")}
                            players={ingame?.blueTeam}
                            enemyPlayers={ingame?.redTeam}
                        />
                        <IngameProvider.PlayerTable
                            region={region}
                            open={open}
                            onClickMore={onClickMore}
                            teamColor={theme.color.red_100}
                            teamName={t("ingame.redTeam")}
                            players={ingame?.redTeam}
                            enemyPlayers={ingame?.blueTeam}
                        />
                    </TableWrapper>
                    {/*<TierWrapper>*/}
                    {/*    <IngameProvider.TeamChart*/}
                    {/*        region={region}*/}
                    {/*        chartList={ingame?.chartList?.blue}*/}
                    {/*        teamColor={'#5682DB'}*/}
                    {/*        teamName={t("ingame.blueTeamChart")}/>*/}
                    {/*    <IngameProvider.TeamChart*/}
                    {/*        region={region}*/}
                    {/*        chartList={ingame.chartList?.red}*/}
                    {/*        teamColor={'#DE4469'}*/}
                    {/*        teamName={t("ingame.redTeamChart")}/>*/}
                    {/*</TierWrapper>*/}
                </IngameProvider>
            </IngameWrapper>
        </ErrorBoundary>
    );
};

export default React.memo(Ingame);
