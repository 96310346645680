import React from 'react';
import {useSelector} from "react-redux";

const KoreanOnlyAd = ({children}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    if(currentLang !== 'KOR') {
        return null
    }
    return children;
};

export default KoreanOnlyAd;