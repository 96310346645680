import React from 'react';
import styled from "styled-components";
import VenatusAd from "./VenatusAd";

const Wrapper = styled.div`
  z-index: 99999999;
  height: 90px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;
const BottomStickAd = () => {
    return (
        <VenatusAd placementName={'horizontal_sticky'}/>
    );
};

export default BottomStickAd;