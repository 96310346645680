import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {changeRegion} from "../../../store/modules/region";
import SearchRegion from "../../Search/SearchRegion";
import SearchList from "../SearchList";
import BookmarkList from "../BookmarkList";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import PlaceHolderTag from "../PlaceHolderTag";
import useSummonerSearch from "../../../common/hooks/useSummonerSearch";
import _ from "lodash";
import useChampionFunc from "../../../common/hooks/useChampionFunc";
import AutoComplete from "./AutoComplete";
import {replaceBlockUnicode} from "../../../function";

const Wrapper = styled.div`
  width: 736px;
`;

const AutoCompleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${props => props.theme.color.purple_100};
  border-radius: 26px;
  padding: 9px 15px 9px 10px;
  box-shadow: 0 0px 6px rgba(0, 76, 255, 0.3);
  background-color: ${props => props.theme.color.site_main_color};
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-left: 20px;
`;

const SearchWrapper = styled.div`
  cursor: pointer;
`;

const SearchInput = styled.input`
  width: 100%;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  color: ${props => props.theme.color.dark_100};
`;

const AbsoluteWrapper = styled.div`
  z-index: 1;
  top: 38px;
`;

const AutoCompleteBox = styled.div`
  width: 595px;
`;

const TabWrapper = styled(AutoCompleteBox)`
  width: 595px;
  border-radius: 6px;
  box-shadow: 0 0px 6px rgba(0, 76, 255, 0.3);
`;

const InputBox = styled.div`
  width: 100%;
  position: relative;
`;

const InputPlaceHolderLabel = styled.label`
  position: absolute;
  line-height: 20px;
  cursor: inherit;
  color: ${props => props.theme.color.dark_300};
  display: flex;
  flex-direction: row;
  align-items: center;

  .tag_span {
    font-size: 14px;
  }
`;
const AbsoluteWidth = styled.div`
  margin-top: 5px;
  padding-left: 58px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const NewSearch = () => {
        const theme = useTheme();
        const {t} = useTranslation();
        const region = useSelector((state) => state.region.result);
        const [summonerName, setSummonerName] = useState('');
        const [inputValue, setInputValue] = useState('');
        const [typed, setTyped] = useState(false);
        const dispatch = useDispatch();
        const history = useHistory();
        const absoluteRef = useRef(null);
        const inputRef = useRef(null);
        useRef(false);
        const [isFocused, setIsFocused] = useState(true);
        const {getChampionByName, navigateToBuild} = useChampionFunc();
        const {navigateToSummonerSearch} = useSummonerSearch();

        useCallback((title) => {
            dispatch(changeRegion(title));
        }, []);

        const onClickSearch = useCallback(() => {

            if (summonerName[0] === '/') {
                return history.push({
                    pathname: summonerName,
                });
            }

            const champion = getChampionByName(summonerName);


            if (champion) {
                return navigateToBuild(champion);
            }


            const regExp = /(joined the room.)|(joined the lobby.)|로비에|(がロビーに参加しました)|,|(,\s),/gi;
            if (regExp.test(summonerName) === false) {
                // 전적검색으로
                if (summonerName !== "") {
                    if (summonerName.indexOf('#') === -1) {
                        return navigateToSummonerSearch(summonerName);
                    }
                    const tagReplaced = summonerName.replace("#", '-');
                    history.push({
                        pathname: `/summoner/${region.toLowerCase()}/${tagReplaced}`,
                        state: {name: summonerName},
                    });
                }
                // history.go(0);
            } else {
                // 멀티서치로
                let arr_str = summonerName.replace(/(joined the room.)|(joined the lobby.)|(joined the lobby)|(님이 로비에 참가했습니다.)|(님이 로비에 참가하셨습니다.)|(がロビーに参加しました)|(,\s)|,|\n|\r/gi, '/');
                arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
                arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
                arr_str = arr_str.replace(/\/$/gi, '');
                arr_str = arr_str.split('/');

                // 중복, 빈값 제거
                const set = new Set(arr_str);
                const uniqueArr = [...set];
                const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

                history.replace({
                    pathname: `/multi/${region.toLowerCase()}/${modyArr}`,
                    state: {name: modyArr},
                });

            }
        }, [summonerName]);

        const onKeyPressEnter = useCallback((e) => {
            if (e.key === 'Enter') {
                onClickSearch();
            }
        }, [onClickSearch]);

        const autoCompleteDelay = _.debounce((value) => {
            setSummonerName(value);
        }, 1)

        const onChangeSummonerName = (e) => {
            const inputValue = replaceBlockUnicode(e.target.value)

            setInputValue(inputValue);
            setTyped(true);

            autoCompleteDelay(inputValue);
        };

        const onChangeFocusOn = useCallback(() => {
            setIsFocused(true)
        }, []);
        const onChangeFocusOut = useCallback((e) => {
            if (absoluteRef.current && !absoluteRef?.current?.contains(e.target)) {
                setIsFocused(false);
            }
        }, []);


        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.focus();
                document.addEventListener("mousedown", onChangeFocusOut, true)
                return () => {
                    document.removeEventListener("mousedown", onChangeFocusOut, true)
                }
            }
        }, []);


        return (
            <Wrapper ref={absoluteRef} onClick={(e) => e.stopPropagation()}>
                <AutoCompleteWrapper>
                    <div onClick={() => setIsFocused(false)}>
                        <SearchRegion
                            arrowColor={theme.color.dark_100}
                            region={region}
                        />
                    </div>
                    <InputWrapper>
                        <InputBox>
                            {(!isFocused && summonerName.length < 1) && <InputPlaceHolderLabel
                                htmlFor={'search-summoner-home'}>{t('search.placeholder')} + <PlaceHolderTag/></InputPlaceHolderLabel>}

                            <SearchInput
                                autoComplete={"off"}
                                ref={inputRef}
                                onFocus={onChangeFocusOn}
                                onClick={() => setTyped(true)}
                                onKeyPress={onKeyPressEnter}
                                onChange={onChangeSummonerName}
                                value={inputValue}
                                id={'search-summoner-home'}
                            />
                        </InputBox>
                        <SearchWrapper onClick={onClickSearch}>
                            <img
                                src={`${theme.icon.path}/icon_search_color.svg`}
                                width={18}
                                height={18}
                                alt={''}
                            />
                        </SearchWrapper>


                    </InputWrapper>

                </AutoCompleteWrapper>
                {typed &&
                    <div >
                        <AbsoluteWidth>
                            <AbsoluteWrapper>
                                {summonerName.length > 0 ?
                                    <AutoCompleteBox>
                                        <AutoComplete
                                            focus={isFocused}
                                            region={region}
                                            summonerName={summonerName}
                                        />
                                    </AutoCompleteBox>
                                    :
                                    isFocused && (
                                        <TabWrapper>
                                            <Tabs>
                                                <TabList>
                                                    <Tab>{t('buttons.recentSearch')}</Tab>
                                                    <Tab>{t('buttons.favorite')}</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <SearchList/>
                                                </TabPanel>
                                                <TabPanel>
                                                    <BookmarkList/>
                                                </TabPanel>
                                            </Tabs>
                                        </TabWrapper>)
                                }
                            </AbsoluteWrapper>
                        </AbsoluteWidth>
                    </div>
                }
            </Wrapper>
        );
    }
;

export default NewSearch;
