import {useEffect} from "react";

const ProsperNewSession = () => {
    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        self.__VM = self.__VM || [];
        // eslint-disable-next-line no-restricted-globals
        self.__VM.push(function (admanager, scope) {
            console.log("[PROSPER] New page session. out");
            scope.Instances.pageManager.on(
                "navigated",
                () => {
                    // this should trigger everytime you consider the content a "new page"
                    scope.Instances.pageManager.newPageSession(false);
                    console.log("[PROSPER] New page session.");
                },
                false,
            );
        });

        return () => {};
    }, []);
    return null;
};

export default ProsperNewSession;