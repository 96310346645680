import React, {useCallback, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import TableHeader from "./Table/TableHeader";
import TableContent from "./Table/TableContent";
import {isAramMap, sortChampData, sortNestedObject} from "../../../../../function";
import WinTableHeader from "./Table/TableHeaderWin";
import {useTranslation} from "react-i18next";
import {dark, light} from "../../../../../common/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .light + .light {
    border-top: 1px solid ${props => light.default_border_color};
  }

  .dark + .dark {
    border-top: 1px solid ${props => dark.default_border_color};
  }
`;

const TableWrapper = styled.table`
  position: relative;
  width: 100%;
  border-style: solid;
`;

const TableHeaderWrapper = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;

const MAX_GRAPH_INDEX = 1;

const GameSummary = ({
                         match,
                         myMatch,
                         duration,
                         AiMemo,
                         region,
                         onClickAi,
                         gameVersion,
                         gameType,
                         hide,
                         onChangeBuildIndex,
                         onClickRankGraph,
                         hoverScale,
                         map
                     }) => {
    const [graphIndex, setGraphIndex] = useState(1);


    const [sort, setSort] = useState([
        {
            value: 'sideIndex',
            sortType: false,
        }, {
            value: 'laneIndex',
            sortType: false,
        }]);

    const rankSrot = [{
        value: 'ai_score',
        sortType: false,
    }];
    const [sortData, setSortData] = useState(() => (
        {
            sideIndex: {
                value: 'sideIndex',
                sortType: false,
            },
            ai_score: {
                value: 'ai_score',
                sortType: true,
            },
            KDA: {
                value: 'KDA',
                sortType: true,
            },
            total_damage_dealt: {
                value: 'total_damage_dealt',
                sortType: true,
            },
            total_damage_taken: {
                value: 'total_damage_taken',
                sortType: true,
            },
            "final_stat_dict.cs": {
                value: "final_stat_dict.cs",
                sortType: true,
            },
        }
    ))


    const MatchMemo = useMemo(() => sortNestedObject(match, sort), [sort]);
    const RankSort = useMemo(() => sortChampData(match, rankSrot), [match]);
    const redTeam = useMemo(() => MatchMemo.filter((data) => data.sideIndex === 0), [MatchMemo]);
    const blueTeam = useMemo(() => MatchMemo.filter((data) => data.sideIndex === 1), [MatchMemo]);

    const Alldamage = useMemo(() => match.reduce((sum, data, index) => {
        if (sum.totaldmg < data.total_damage_dealt) {
            sum.totaldmg = data.total_damage_dealt;
        }

        if (sum.totalTaken < data.total_damage_taken) {
            sum.totalTaken = data.total_damage_taken;
        }

        return sum;
    }, {totaldmg: 0, totalTaken: 0}), [match]);


    const allOffObject = useCallback((copyData) => {
        if (typeof copyData === 'object') {
            const copy = {...copyData};
            Object.keys(copy).forEach((key) => {
                copy[key].sortType = true;
            })
            return copy;
        }
        return copyData;
    }, [])

    //FALSE == ON TRUE == OFF
    const onClickSort = useCallback((data) => {

        if (typeof data === 'object') {
            const copyData = {...data};
            const copySortData = {...sortData};
            if (copyData) {
                if (copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setSort([{...copyData}]);
                    setSortData(copySortData);
                }
            }
        }
    }, [sortData]);


    useCallback(() => {
        setGraphIndex((prev) => {
            if (prev + 1 > MAX_GRAPH_INDEX) {
                return 0;
            } else {
                return prev + 1;
            }
        })
    }, []);


    return (
        <Wrapper>
            <Table
                hoverScale={hoverScale}
                onClickRankGraph={onClickRankGraph}
                onChangeBuildIndex={onChangeBuildIndex}
                team={blueTeam}
                region={region}
                hide={hide}
                gameType={gameType}
                myMatch={myMatch}
                onClickSort={onClickSort}
                AiMemo={AiMemo}
                graphIndex={graphIndex}
                Alldamage={Alldamage}
                duration={duration}
                RankSort={RankSort}
                gameVersion={gameVersion}
                onClickAi={onClickAi}
                map={map}
            />
            <Table
                map={map}
                hoverScale={hoverScale}
                onClickRankGraph={onClickRankGraph}
                onChangeBuildIndex={onChangeBuildIndex}
                team={redTeam}
                region={region}
                hide={hide}
                gameType={gameType}
                myMatch={myMatch}
                onClickSort={onClickSort}
                AiMemo={AiMemo}
                graphIndex={graphIndex}
                Alldamage={Alldamage}
                duration={duration}
                RankSort={RankSort}
                gameVersion={gameVersion}
                onClickAi={onClickAi}
            />
        </Wrapper>
    );
};


const Table = ({
                   hoverScale,
                   gameType,
                   onClickSort,
                   AiMemo,
                   myMatch,
                   hide,
                   region,
                   Alldamage,
                   team,
                   graphIndex = 0,
                   duration,
                   gameVersion,
                   onClickAi,
                   RankSort,
                   onChangeBuildIndex,
                   onClickRankGraph,
                   map
               }) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return (
        <TableWrapper className={theme.color.theme}>
            <colgroup>
                <col width={'32%'}/>
                <col width={'8%'}/>
                <col width={'9%'}/>
                <col width={'8%'}/>
                {isAramMap(gameType,map) ? <col width={'6%'}/> : <>
                    <col width={'7%'}/>
                    <col width={'7%'}/>
                </>}
                <col width={'18%'}/>
            </colgroup>
            <thead>
            <TableHeaderWrapper>
                <WinTableHeader
                    isWin={team[0].is_win}
                    teamIndex={team[0].sideIndex}
                    onClickSort={onClickSort}
                    percent={25}
                    title={t('gameAnalytics.summoner')}
                />
                <TableHeader
                    onClickSort={onClickSort}
                    percent={8}
                    title={"AI-Score"}/>
                <TableHeader
                    onClickSort={onClickSort}
                    percent={8}
                    title={"KDA"}
                />
                {graphIndex === 1 &&
                    <TableHeader
                        onClickSort={onClickSort}
                        percent={isAramMap(gameType,map) ? 22 : 16}
                        title={t('gameAnalytics.damage')}/>}
                {isAramMap(gameType,map) ?
                    <TableHeader
                        percent={12}
                        title={t('gameAnalytics.multiKill')}/>
                    :
                    null
                }
                {!isAramMap(gameType,map) ?
                    <>
                        <TableHeader
                            onClickSort={onClickSort}
                            percent={9}
                            title={"CS"}/>
                        <TableHeader
                            percent={5}
                            title={t('gameAnalytics.ward')}/>
                    </>
                    : null}
                <TableHeader
                    percent={27} title={t('gameAnalytics.item')}/>
            </TableHeaderWrapper>
            </thead>
            <tbody>
            {team.map((data) => {
                const findRank = RankSort.findIndex((find) => find.puu_id === data.puu_id);
                const findMvp = AiMemo[0].puu_id === data.puu_id;
                const findAce = AiMemo[5].puu_id === data.puu_id;
                const isme = data.puu_id === myMatch.puu_id;
                return (
                    <TableContent
                        hoverScale={hoverScale}
                        onClickRankGraph={onClickRankGraph}
                        onChangeBuildIndex={onChangeBuildIndex}
                        hide={hide}
                        gameType={gameType}
                        gameVersion={gameVersion}
                        graphIndex={graphIndex}
                        onClickAi={onClickAi}
                        region={region}
                        isme={isme}
                        duration={duration}
                        allDamage={Alldamage}
                        isMvp={findMvp}
                        isAce={findAce}
                        rank={findRank + 1}
                        key={data.puu_id}
                        data={data}
                        map={map}
                    />
                )
            })}
            </tbody>
        </TableWrapper>
    )
}

export default React.memo(GameSummary);
