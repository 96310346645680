import React from 'react';
import {Header} from "../common";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 88px;
  margin-bottom: 140px;
`;
const PrivacyHeader = styled.div`
  height: 54px;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  border-bottom: 2px solid ${props => props.theme.color.green_100};
  color: ${props => props.theme.color.black_100};
`;
const ContentBox = styled.div`
  width: 1080px;
`;
const DateText = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 24px 0;
  color: ${props => props.theme.color.black_100};
`;

const WarningText = styled.div`
  color: ${props => props.theme.color.dark_200};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
  line-height: 18px;
`;

const RuleTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${props => props.theme.color.black_100};
`;

const EndBox = styled.div`
  margin-top: 24px;
`;
const EndText = styled.div`
  font-weight: 300;
  font-size: 16px;
  color: ${props => props.theme.color.dark_100};
`;
const RuleContent = styled(EndText)`
  margin-left: 8px;
  margin-top: 8px;
  line-height: 24px;
  white-space: pre-wrap;
`;

const RuleBox = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

const Rule8Box = styled.div`
  display: flex;
  flex-direction: column;
  a:hover {
    
    text-decoration: underline;
  }
`;
const Privacy = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Header/>
            <ContentWrapper>
                <ContentBox>
                    <PrivacyHeader>
                        <span>{t('privacy.title')}</span>
                    </PrivacyHeader>
                    <DateText>
                        <span>{t('privacy.update')}: 2025-02-19</span>
                    </DateText>
                    <WarningText>
                        <span>{t('privacy.warning')}</span>
                    </WarningText>
                    <RuleItem title={t('privacy.ruleTitle01')}>
                        <span>{t('privacy.ruleContent01')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle02')}>
                        <span>{t('privacy.ruleContent02')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle03')}>
                        <span>{t('privacy.ruleContent03')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle04')}>
                        <span>{t('privacy.ruleContent04')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle05')}>
                        <span>{t('privacy.ruleContent05')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle06')}>
                        <span>{t('privacy.ruleContent06')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle07')}>
                        <span>{t('privacy.ruleContent07')}</span>
                    </RuleItem>
                    <RuleItem title={t('privacy.ruleTitle08')}>
                        <Rule8Box>
                            <span>{t('privacy.ruleContent08')}</span>
                            <a href={'https://deeplol.atlassian.net/servicedesk/customer/portal/1/group/1/create/39'} target={'_blank'}>{t('privacy.ruleContent08Extra')}</a>
                        </Rule8Box>
                    </RuleItem>
                    <EndBox>
                        <EndText>
                            <span>{t('privacy.end')}</span>
                        </EndText>
                    </EndBox>
                </ContentBox>
            </ContentWrapper>
        </div>
    );
};


const RuleItem = ({title, children}) => {
    return (
        <RuleBox>
            <RuleTitle>
                <span>{title}</span>
            </RuleTitle>
            <RuleContent>
                {children}
            </RuleContent>
        </RuleBox>
    )
}

export default Privacy;