import React from 'react';
import styled from "styled-components";
import {Header} from "../common";
import RankingContent from "./RankingContent";
import {useTranslation} from "react-i18next";
import {ranking_ad_content} from "../../ad/bidrich";
import useAdHooks from "../../common/hooks/useAdHooks";
import {ErrorBoundary} from "react-error-boundary";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import {useParams} from "react-router-dom";
import useLaneTrans from "../../common/hooks/useLaneTrans";
import BottomStickAd from "../ad/BottomStickAd";

const Wrapper = styled.div`
  padding-bottom: 3vh;
`;

function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}

const Ranking = () => {
    UseSetCanonical();
    const trans = useLaneTrans();
    const {position, platform} = useParams();
    const {t} = useTranslation();
    useAdHooks(ranking_ad_content);

    useTitleAndDescription({
        title: t('header.titleRanking', {
            server: platform.toUpperCase(),
            position: position === 'all' ? '' : trans(position)
        }),
        description: t('header.titleRankingDescription', {
            server: platform.toUpperCase(),
            position: position === 'all' ? '' : trans(position)
        })
    })
    return (
        <Wrapper>
            <Header/>
            <ErrorBoundary FallbackComponent={Fallback}>
                <RankingContent/>
            </ErrorBoundary>
            <BottomStickAd/>

            {/*<Maintance/>*/}
        </Wrapper>
    );
};

export default Ranking;
