import React from 'react';
import {FlexBox} from "../../ui/styled";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";


const DarkModeText = styled.span`
  font-size: 12px;
  white-space: pre-wrap;
  line-height: 17px;
  font-weight: 700;
  color: ${props => props.theme.color.dark_100};
  margin: 0 4px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  right: -37px;
`;

const ExtraText = styled.span`
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: ${props => props.theme.color.red_600};
`;
const GreenText = styled.span`
  color: #0DBAAE;
`;

const Wrapper = styled(FlexBox)`
  position: relative;
  cursor: pointer;
  height: 133px;
`;
const BlackText = styled.span`
  margin-left: 5px;
  text-align: left;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin-top: 4px;
  white-space: pre-wrap;
`;

const EmptyWrapper = styled.div`
  height: 133px;
`;

const AbsoluteWrapper = styled.div`
    position: absolute;
`;

const HomeNotice = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);



    return (
        <EmptyWrapper/>
    )
    // if (!openStoryRegion(region)) {
    //     return <EmptyWrapper/>
    // }
    //
    // return (
    //     <Link to={'/event'}>
    //         <Wrapper justifyContent={'flex-end'} height={'auto'} width={'736px'}
    //                  alignItems={'flex-start'} >
    //             <ButtonWrapper>
    //                 {/*<img src={'/images/event/gosegu_home_banner.svg'} alt={''}/>*/}
    //             </ButtonWrapper>
    //         </Wrapper>
    //     </Link>
    // )
};

export default HomeNotice;