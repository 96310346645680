import {getRegion} from "../function";
import {secretApi} from "./api";


export const summonerSearchAPI = async ({platform_id, riot_id_name}) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/list`);
    url.searchParams.set('platform_id', getRegion(platform_id));
    url.searchParams.set('riot_id_name', riot_id_name);
    const result = await secretApi.get(url.toString())
    return result.data;
}