import React, {useLayoutEffect, useState} from 'react';
import {getRegion} from "../../function";
import {secretApi} from "../../api/api";

const useStreamerTag = ({puu_id, platform, status}) => {
    const [streamerProTag, setStreamerProTag] = useState({});

    useLayoutEffect(() => {
        if (status?.name && status?.status) {
            setStreamerProTag(status)
            return;
        }
        if (puu_id) {
            const url = new URL(`${process.env.REACT_APP_CACHE_API}match/stream-pro-tag`);
            url.searchParams.set("platform_id", getRegion(platform));
            url.searchParams.set("puu_id", puu_id);
            secretApi.get(url.toString()).then((res) => {
                const item = res?.data?.stream_pro_tag;
                if (item?.length > 0) {
                    setStreamerProTag(item[0]);
                }
            }).catch(() => {
                setStreamerProTag({});
            });
        }
    }, [puu_id, platform]);

    return {
        streamerProTag
    }
};

export default useStreamerTag;