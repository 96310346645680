import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {GET_ARAM_BALANCE_FAILURE, GET_ARAM_BALANCE_REQUEST, GET_ARAM_BALANCE_SUCCESS} from "../reducer/balance";
import {getStateVersion} from "./champions";
import {secretApi} from "../../api/api";

const getAramBalanceData = ({version}) => {
    const url = `${process.env.REACT_APP_CACHE_API}champion/balance?version=${version}`;
    return secretApi.get(url).then((result) => result.data);
}


function* getAramBalance() {

    try {
        const version = yield call(getStateVersion);
        const getData = yield call(getAramBalanceData,{version: version[0]});

        yield put({
            type: GET_ARAM_BALANCE_SUCCESS,
            data: getData
        })
    } catch (e) {

        yield put({
            type: GET_ARAM_BALANCE_FAILURE,
            error: e,
        })
    }
}

function* watchGetRanking() {
    yield takeLatest(GET_ARAM_BALANCE_REQUEST, getAramBalance)
}


export default function* balanceSaga() {
    yield all([
        fork(watchGetRanking),
    ])
}
